import React, { useState } from "react"
import { Link } from "gatsby"

export default function NavItems({ isMobileMenu = false, onMenuItemClick = () => {} }) {
  const [selectedMenuItem, setSelectedMenuItem] = useState("")

  const subMenuHandler = (key, ev) => {
    setSelectedMenuItem(key === selectedMenuItem ? "" : key)
    if (isMobileMenu) {
      ev.preventDefault()
      return false
    }
  }

  const pathname = typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <>
      <li>
        <Link to="/" activeClassName="active" onClick={onMenuItemClick}>
          Home
        </Link>
      </li>
      <li
        class={`has-submenu menu-item-has-children ${
          selectedMenuItem === "about" ? `is--active` : ``
        }`}
      >
        <Link
          to="/about-us/who-we-are"
          className={"has-child" + (pathname.includes("about-us/") ? " active" : "")}
          onClick={e => {
            //onMenuItemClick()
            subMenuHandler("about", e)
          }}
        >
          About Us
        </Link>
        <span class="plus" onClick={e => subMenuHandler("about", e)} aria-hidden={true}>
          <i className="fe-chevron-right"></i>
        </span>
        <ul class={`sub-menu ${selectedMenuItem === "about" ? `is--active` : ``}`}>
          <li>
            <Link to="/about-us/who-we-are" activeClassName="active" onClick={onMenuItemClick}>
              Who we are
            </Link>
          </li>
          <li>
            <Link to="/about-us/our-philosophy" activeClassName="active" onClick={onMenuItemClick}>
              Our Philosophy
            </Link>
          </li>
          <li>
            <Link to="/about-us/why-choose-us" activeClassName="active" onClick={onMenuItemClick}>
              Why choose us?
            </Link>
          </li>
          {/* <li>
            <Link
              to="/about-us/association-with-ifast"
              activeClassName="active"
              onClick={onMenuItemClick}
            >
              Association with iFast
            </Link>
          </li> */}
        </ul>
      </li>

      <li
        class={`has-submenu menu-item-has-children ${
          selectedMenuItem === "services" ? `is--active` : ``
        }`}
      >
        <Link
          to="/services/offerings"
          className={"has-child" + (pathname.includes("services/") ? " active" : "")}
          onClick={e => {
            //onMenuItemClick()
            subMenuHandler("services", e)
          }}
        >
          Services
        </Link>
        <span class="plus" onClick={e => subMenuHandler("services", e)} aria-hidden={true}>
          <i className="fe-chevron-right"></i>
        </span>
        <ul class={`sub-menu ${selectedMenuItem === "services" ? `is--active` : ``}`}>
          <li>
            <Link
              to="/services/offerings"
              activeClassName="active"
              partiallyActive={true}
              onClick={onMenuItemClick}
            >
              Offerings
            </Link>
          </li>
          <li>
            <Link to="/services/client-journey" activeClassName="active" onClick={onMenuItemClick}>
              Client Journey
            </Link>
          </li>
          <li>
            <Link
              to="/services/client-engagement"
              activeClassName="active"
              onClick={onMenuItemClick}
            >
              Client Engagement
            </Link>
          </li>
          <li>
            <Link to="/services/fee-philosophy" activeClassName="active" onClick={onMenuItemClick}>
              Fee Philosophy
            </Link>
          </li>
        </ul>
      </li>

      <li
        class={`has-submenu menu-item-has-children ${
          selectedMenuItem === "clients" ? `is--active` : ``
        }`}
      >
        <Link
          to="/clients/business-owner"
          className={"has-child" + (pathname.includes("clients/") ? " active" : "")}
          onClick={e => {
            subMenuHandler("clients", e)
          }}
        >
          Clients
        </Link>
        <span class="plus" onClick={e => subMenuHandler("clients", e)} aria-hidden={true}>
          <i className="fe-chevron-right"></i>
        </span>
        <ul class={`sub-menu ${selectedMenuItem === "clients" ? `is--active` : ``}`}>
          <li>
            <Link to="/clients/business-owner" activeClassName="active" onClick={onMenuItemClick}>
              Business Owner
            </Link>
          </li>
          <li>
            <Link
              to="/clients/professional-salaried-employee"
              activeClassName="active"
              onClick={onMenuItemClick}
            >
              Professional
            </Link>
          </li>
          <li>
            <Link to="/clients/woman" activeClassName="active" onClick={onMenuItemClick}>
              Woman
            </Link>
          </li>
          <li>
            <Link to="/clients/retirees" activeClassName="active" onClick={onMenuItemClick}>
              Retirees
            </Link>
          </li>
        </ul>
      </li>

      <li
        class={`has-submenu menu-item-has-children ${
          selectedMenuItem === "resources" ? `is--active` : ``
        }`}
      >
        <Link
          to="/resources/blog"
          className={"has-child" + (pathname.includes("resources/") ? " active" : "")}
          onClick={e => {
            //onMenuItemClick()
            subMenuHandler("resources", e)
          }}
        >
          Resources
        </Link>
        <span class="plus" onClick={e => subMenuHandler("resources", e)} aria-hidden={true}>
          <i className="fe-chevron-right"></i>
        </span>
        <ul class={`sub-menu ${selectedMenuItem === "resources" ? `is--active` : ``}`}>
          {/* <li>
            <Link
              to="/resources/investment-letters"
              activeClassName="active"
              onClick={onMenuItemClick}
            >
              Investment Letters
            </Link>
          </li> */}
          <li>
            <Link
              to="/resources/blog"
              activeClassName="active"
              partiallyActive={true}
              onClick={onMenuItemClick}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="/resources/media"
              activeClassName="active"
              partiallyActive={true}
              onClick={onMenuItemClick}
            >
              Media
            </Link>
          </li>
          <li>
            <Link
              to="/resources/tools"
              activeClassName="active"
              partiallyActive={true}
              onClick={onMenuItemClick}
            >
              Tools
            </Link>
          </li>
          <li class="d-none">
            <Link to="/resources/faq" activeClassName="active" onClick={onMenuItemClick}>
              FAQs
            </Link>
          </li>
          <li>
            <Link
              to="/resources/book-summaries"
              activeClassName="active"
              partiallyActive={true}
              onClick={onMenuItemClick}
            >
              Book Summaries
            </Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to="/statutory" activeClassName="active" onClick={onMenuItemClick}>
          Statutory
        </Link>
      </li>

      <li>
        <Link to="/contact-us" activeClassName="active" onClick={onMenuItemClick}>
          Contact Us
        </Link>
      </li>
    </>
  )
}
