import React from "react"

import Header from "../components/header"
import GetInTouch from "../components/getintouch"
import Footer from "../components/footer"

export default function Layout({ children }) {
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  let hideGetInTouch =
    pathname.includes("disclaimer") || pathname.includes("contact-us") ? true : false

  return (
    <>
      <Header />

      <div class="clearfix"></div>

      {children}

      {!hideGetInTouch && (
        <>
          <div class="clearfix"></div>

          <GetInTouch />
        </>
      )}

      <div class="clearfix"></div>

      <Footer />
    </>
  )
}
