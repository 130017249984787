import React from "react"
import { Helmet } from "react-helmet"

export default function Seo({ title = null, description = null }) {
  let metaTitle = title ? title + " | APRICUS" : "APRICUS"
  let metaDescription = description ?? "Apricus Wealth Advisors"

  return (
    <Helmet
      bodyAttributes={{
        class: "body",
      }}
    >
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="shortcut icon" href="/favicon/coloured/favicon.png" />
      <link rel="icon" href="/favicon/coloured/favicon.svg" sizes="any" type="image/svg+xml" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="/favicon/coloured/apple-touch-icon-57x57-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="/favicon/coloured/apple-touch-icon-72x72-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="/favicon/coloured/apple-touch-icon-114x114-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="/favicon/coloured/apple-touch-icon-144x144-precomposed.png"
      />

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
  )
}
