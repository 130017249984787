// const dev = {
//   API_BASE_URL: "http://dev.apricuswealth.in/api/",
// }

const live = {
  API_BASE_URL: "https://api.apricuswealth.in/api/",
}

export default {
  ...live,
}
