import React from "react"
import NavItems from "../components/navitems"

export default function Nav() {
  return (
    <nav class="c-navigation c-navigation-md">
      <ul class="c-navigation--list">
        <NavItems />
      </ul>
    </nav>
  )
}
