import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Nav from "./nav"
import Logo from "../static/images/logo.svg"

export default function NavSticky({ onMenuClick, isUlMenuActive }) {
  const [classes, setClasses] = useState("c-page-menu c-page-menu-sm")

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 100) {
        setClasses("c-page-menu c-page-menu-sm c-sticky-page-menu")
      } else {
        setClasses("c-page-menu c-page-menu-sm")
      }
    }
  }, [])

  return (
    <div id="c-page-menu" class={classes}>
      <div id="c-page-menu-wrap" class="c-page-menu-wrap c-page-menu-wrap-sm">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="c-header__main-info c-header__main-info-sm">
                <div class="c-page-menu--logo c-page-menu--logo-sm">
                  <Link to="/">
                    <img src={Logo} class="image-fluid" alt="Apricus Wealth Advisors" />
                  </Link>
                </div>

                <div class="c-header__login-info c-header__login-info-sm ml-auto">
                  <Nav />
                </div>

                <button class="c-sticky-hamMenu js-hamDevice" type="button">
                  <span class="transition-med"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
