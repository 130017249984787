import "./src/style/sass/vendors.scss"
import "./src/style/sass/default.scss"

import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}
