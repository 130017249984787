import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <div class="c-footer__bottom c-footer__bottom-sm">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-sm-12">
            <div class="c-footer__bottom--copyright text-left sm:text-center">
              Copyright &copy; 2023 Apricus Wealth Advisors. All rights reserved.
            </div>
            <div class="widget widget-md mt-3">
              <div class="widget--social widget--social-sm">
                <a href="https://twitter.com/ApricusWealth" target="_blank" rel="noreferrer">
                  <i class="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/apricus-wealth-12284b205/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-sm-12 text-right sm:mmt-5">
            <div class="c-footer__navigation c-footer__navigation-md sm:float-center">
              <Link to="/">Home</Link>
              <Link to="/about-us/who-we-are">About Us</Link>
              <Link to="/contact-us">Contact Us</Link>
              <Link to="/disclaimer">Disclaimer</Link>
            </div>
            <div class="mt-3 sm:text-center">LLPIN: ABC-4926</div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export function FooterRemoved() {
//   return (
//     <footer class="c-footer c-footer-sm">
//       <div class="container">
//         <div class="row">
//           <div class="col-12">
//             <div class="c-footer__container">
//               <div class="row">
//                 <div class="col-12 col-md-4">
//                   <Link to="/" class="c-footer--logo">
//                     <img
//                       src="static/images/logo-white.svg"
//                       class="image-fluid"
//                       alt="Apricus Wealth Advisors"
//                     />
//                   </Link>

//                   <div class="c-footer--about c-footer--about-md">
//                     <p>
//                       Kunal Bhatia of Apricus Wealth Advisors is an Investment Adviser of iFAST
//                       Global Markets, the investment advisory division of iFAST Financial India.
//                       iFAST is a corporate SEBI Registered Investment Adviser. Details of iFast
//                       Financial India:
//                     </p>
//                   </div>
//                 </div>

//                 <div class="col-12 col-md-8">
//                   <div class="row">
//                     <div class="col-12 col-md-6">
//                       <div class="widget widget-md">
//                         <div class="widget--title">
//                           <h4>Quick Contact</h4>
//                         </div>
//                         <div class="c-footer--address c-footer--address-sm">
//                           <ul class="">
//                             <li>
//                               <strong>iFAST Financial India Private Limited</strong>
//                             </li>
//                             <li>Type of Registration-Non-Individual</li>
//                             <li>Registration number – INA000000763 (Validity: Perpetual)</li>
//                           </ul>

//                           <ul class="">
//                             <li>
//                               <strong>Address</strong>
//                             </li>
//                             <li>
//                               1, Raheja Titanium, Western Express Highway, Goregaon East, Mumbai –
//                               400063 India
//                             </li>
//                             <li>Tel: (+91-22-42199490)</li>
//                           </ul>

//                           <ul class="">
//                             <li>
//                               <strong>Contact details of the Principal Officer:</strong>
//                             </li>
//                             <li>Name: Mr. Hon Hee Siew</li>
//                             <li>Contact number -022-42199490</li>
//                             <li>Email id- compliance.in@ifastfinancial.com</li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                     <div class="col-12 col-md-6">
//                       <div class="widget widget-md">
//                         <div class="widget--title">
//                           <h4>Quick Links</h4>
//                         </div>
//                         <div class="c-footer__navigation c-footer__navigation-md">
//                           <Link to="/index.php">Home</Link>
//                           <Link to="/about-us.php">About Us</Link>
//                           <Link to="/contact-us.php">Contact Us</Link>
//                           <Link to="/disclaimer.php">Disclaimer</Link>
//                         </div>
//                       </div>

//                       <div class="widget widget-md mt-5">
//                         <div class="widget--social">
//                           <Link to="/">
//                             <i class="fab fa-facebook-f"></i>
//                           </Link>
//                           <Link to="/">
//                             <i class="fab fa-linkedin-in"></i>
//                           </Link>
//                           <Link to="/">
//                             <i class="fab fa-instagram"></i>
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   )
// }
