// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-our-philosophy-js": () => import("./../../../src/pages/about-us/our-philosophy.js" /* webpackChunkName: "component---src-pages-about-us-our-philosophy-js" */),
  "component---src-pages-about-us-who-we-are-js": () => import("./../../../src/pages/about-us/who-we-are.js" /* webpackChunkName: "component---src-pages-about-us-who-we-are-js" */),
  "component---src-pages-about-us-why-choose-us-js": () => import("./../../../src/pages/about-us/why-choose-us.js" /* webpackChunkName: "component---src-pages-about-us-why-choose-us-js" */),
  "component---src-pages-clients-business-owner-js": () => import("./../../../src/pages/clients/business-owner.js" /* webpackChunkName: "component---src-pages-clients-business-owner-js" */),
  "component---src-pages-clients-professional-salaried-employee-js": () => import("./../../../src/pages/clients/professional-salaried-employee.js" /* webpackChunkName: "component---src-pages-clients-professional-salaried-employee-js" */),
  "component---src-pages-clients-retirees-js": () => import("./../../../src/pages/clients/retirees.js" /* webpackChunkName: "component---src-pages-clients-retirees-js" */),
  "component---src-pages-clients-woman-js": () => import("./../../../src/pages/clients/woman.js" /* webpackChunkName: "component---src-pages-clients-woman-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-blog-js": () => import("./../../../src/pages/resources/blog.js" /* webpackChunkName: "component---src-pages-resources-blog-js" */),
  "component---src-pages-resources-blog-selecting-a-fund-manager-js": () => import("./../../../src/pages/resources/blog/selecting-a-fund-manager.js" /* webpackChunkName: "component---src-pages-resources-blog-selecting-a-fund-manager-js" */),
  "component---src-pages-resources-book-summaries-fooled-by-randomness-nassim-nicholas-taleb-js": () => import("./../../../src/pages/resources/book-summaries/fooled-by-randomness-nassim-nicholas-taleb.js" /* webpackChunkName: "component---src-pages-resources-book-summaries-fooled-by-randomness-nassim-nicholas-taleb-js" */),
  "component---src-pages-resources-book-summaries-influence-the-psychology-of-persuasion-by-robert-b-cialdini-js": () => import("./../../../src/pages/resources/book-summaries/influence-the-psychology-of-persuasion-by-robert-b-cialdini.js" /* webpackChunkName: "component---src-pages-resources-book-summaries-influence-the-psychology-of-persuasion-by-robert-b-cialdini-js" */),
  "component---src-pages-resources-book-summaries-js": () => import("./../../../src/pages/resources/book-summaries.js" /* webpackChunkName: "component---src-pages-resources-book-summaries-js" */),
  "component---src-pages-resources-book-summaries-one-up-on-wall-street-js": () => import("./../../../src/pages/resources/book-summaries/one-up-on-wall-street.js" /* webpackChunkName: "component---src-pages-resources-book-summaries-one-up-on-wall-street-js" */),
  "component---src-pages-resources-book-summaries-the-little-book-that-builds-wealth-pat-dorsey-js": () => import("./../../../src/pages/resources/book-summaries/the-little-book-that-builds-wealth-pat-dorsey.js" /* webpackChunkName: "component---src-pages-resources-book-summaries-the-little-book-that-builds-wealth-pat-dorsey-js" */),
  "component---src-pages-resources-book-summaries-the-most-important-thing-howard-marks-js": () => import("./../../../src/pages/resources/book-summaries/the-most-important-thing-howard-marks.js" /* webpackChunkName: "component---src-pages-resources-book-summaries-the-most-important-thing-howard-marks-js" */),
  "component---src-pages-resources-media-js": () => import("./../../../src/pages/resources/media.js" /* webpackChunkName: "component---src-pages-resources-media-js" */),
  "component---src-pages-resources-tools-5-minute-health-check-js": () => import("./../../../src/pages/resources/tools/5-minute-health-check.js" /* webpackChunkName: "component---src-pages-resources-tools-5-minute-health-check-js" */),
  "component---src-pages-resources-tools-investment-advisory-js": () => import("./../../../src/pages/resources/tools/investment-advisory.js" /* webpackChunkName: "component---src-pages-resources-tools-investment-advisory-js" */),
  "component---src-pages-resources-tools-js": () => import("./../../../src/pages/resources/tools.js" /* webpackChunkName: "component---src-pages-resources-tools-js" */),
  "component---src-pages-resources-tools-risk-profile-js": () => import("./../../../src/pages/resources/tools/risk-profile.js" /* webpackChunkName: "component---src-pages-resources-tools-risk-profile-js" */),
  "component---src-pages-resources-tools-thankyou-js": () => import("./../../../src/pages/resources/tools/thankyou.js" /* webpackChunkName: "component---src-pages-resources-tools-thankyou-js" */),
  "component---src-pages-services-client-engagement-js": () => import("./../../../src/pages/services/client-engagement.js" /* webpackChunkName: "component---src-pages-services-client-engagement-js" */),
  "component---src-pages-services-client-journey-js": () => import("./../../../src/pages/services/client-journey.js" /* webpackChunkName: "component---src-pages-services-client-journey-js" */),
  "component---src-pages-services-fee-philosophy-js": () => import("./../../../src/pages/services/fee-philosophy.js" /* webpackChunkName: "component---src-pages-services-fee-philosophy-js" */),
  "component---src-pages-services-offerings-comprehensive-wealth-management-js": () => import("./../../../src/pages/services/offerings/comprehensive-wealth-management.js" /* webpackChunkName: "component---src-pages-services-offerings-comprehensive-wealth-management-js" */),
  "component---src-pages-services-offerings-direct-equities-advisory-js": () => import("./../../../src/pages/services/offerings/direct-equities-advisory.js" /* webpackChunkName: "component---src-pages-services-offerings-direct-equities-advisory-js" */),
  "component---src-pages-services-offerings-js": () => import("./../../../src/pages/services/offerings.js" /* webpackChunkName: "component---src-pages-services-offerings-js" */),
  "component---src-pages-services-offerings-mutual-funds-advisory-js": () => import("./../../../src/pages/services/offerings/mutual-funds-advisory.js" /* webpackChunkName: "component---src-pages-services-offerings-mutual-funds-advisory-js" */),
  "component---src-pages-services-offerings-second-look-js": () => import("./../../../src/pages/services/offerings/second-look.js" /* webpackChunkName: "component---src-pages-services-offerings-second-look-js" */),
  "component---src-pages-statutory-js": () => import("./../../../src/pages/statutory.js" /* webpackChunkName: "component---src-pages-statutory-js" */)
}

