import React, { useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import config from "../config"

const phoneRegExp = /^[0-9]+$/

const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required("This is a required field"),
  LastName: Yup.string().required("This is a required field"),
  Email: Yup.string().email("Enter a valid email").required("This is a required field"),
  Telephone: Yup.string()
    .matches(phoneRegExp, "Telephone is not valid")
    .min(10, "Telephone should be minimum 10 digits")
    .max(13, "Telephone should be maximum 13 digits")
    .required("This is a required field"),
  Message: Yup.string().required("This is a required field"),
})

export default function GetInTouchForm() {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [showSuccessDiv, setShowSuccessDiv] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const initStateObj = {
    FirstName: "",
    LastName: "",
    Email: "",
    Telephone: "",
    Message: "",
  }

  const formSubmitHandler = async (values, resetFormHandler) => {
    setErrorMessage("")

    if (!executeRecaptcha) {
      return
    }

    const captchaResponse = await executeRecaptcha("get_in_touch_form")

    var bodyFormData = new FormData()

    Object.keys(values).forEach(data => {
      bodyFormData.append(data, values[data])
    })

    bodyFormData.append("g-recaptcha-response", captchaResponse)

    try {
      const contactApiResponse = await axios({
        method: "post",
        url: config.API_BASE_URL + "contact-form-submit.php",
        data: bodyFormData,
      })

      if (contactApiResponse.data.success) {
        setShowSuccessDiv(true)
        resetFormHandler()
        setTimeout(() => {
          setShowSuccessDiv(false)
        }, 8000)
      } else {
        setErrorMessage(contactApiResponse.data.errors)
      }
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  return (
    <Formik
      initialValues={initStateObj}
      onSubmit={(values, { resetForm }) => {
        formSubmitHandler(values, resetForm)
      }}
      validationSchema={validationSchema}
    >
      {({ handleChange, values, handleSubmit, errors, touched, handleBlur }) => (
        <form
          class="form-horizontal mt-4"
          id="contactForm"
          autocomplete="off"
          action="#"
          method="post"
          onSubmit={handleSubmit}
        >
          <div class="row form-group">
            <div class="col-12 col-md-6 col-sm-6 col-xs-6">
              <label htmlFor="FirstName">
                First Name <span class="required">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name="FirstName"
                id="FirstName"
                value={values.FirstName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                autocomplete="off"
              />
              <ErrorMessage component="span" name="FirstName" className="help-block" />
            </div>
            <div class="col-12 col-md-6 col-sm-6 col-xs-6">
              <label htmlFor="LastName">
                Last Name <span class="required">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name="LastName"
                id="LastName"
                value={values.LastName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                autocomplete="off"
              />
              <ErrorMessage component="span" name="LastName" className="help-block" />
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12 col-md-6 col-sm-6 col-xs-6">
              <label htmlFor="Email">
                Email <span class="required">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name="Email"
                id="Email"
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                autocomplete="off"
              />
              <ErrorMessage name="Email" className="help-block" component="span" />
            </div>
            <div class="col-12 col-md-6 col-sm-6 col-xs-6">
              <label htmlFor="Telephone">
                Telephone <span class="required">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name="Telephone"
                id="Telephone"
                value={values.Telephone}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                autocomplete="off"
              />
              <ErrorMessage name="Telephone" className="help-block" component="span" />
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12 col-md-12">
              <label htmlFor="Message">
                Message <span class="required">*</span>
              </label>
              <textarea
                type="text"
                class="form-control"
                name="Message"
                id="Message"
                value={values.Message}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                autocomplete="off"
              ></textarea>
              <ErrorMessage name="Message" className="help-block" component="span" />
            </div>
          </div>

          <div class="row form-group d-none">
            <div class="col-12 col-md-12">
              <div class="button-set">
                <label htmlFor="checkbox0" class="start">
                  <input id="checkbox0" type="checkbox" />
                  <span class="eds-checked">
                    <span class="check"></span>
                  </span>{" "}
                  Please tick this box to confirm you fully understand and accept the privacy
                  policy.{" "}
                </label>
              </div>
            </div>
          </div>

          <div className="mb-3 fs--14 grecaptcha-text">
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://policies.google.com/terms" rel="noreferrer" target="_blank">
              Terms of Service
            </a>{" "}
            apply.
          </div>

          <button
            type="submit"
            class="c-button c-button--blue c-button-radius c-button-inline c-button-lg js-getTouchFormSubmit"
          >
            Get Started
          </button>
          {showSuccessDiv && (
            <div class="alert alert-success mt-3" role="alert">
              <strong>
                Thank you for supplying the relevant information. We'll get back to you.
              </strong>
            </div>
          )}

          {errorMessage && (
            <div class="alert alert-danger mt-3" role="alert">
              <strong>{errorMessage}</strong>
            </div>
          )}
        </form>
      )}
    </Formik>
  )
}
