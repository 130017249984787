import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Nav from "./nav"
import NavSticky from "./navsticky"
import NavItems from "./navitems"
import Seo from "./seo"
import Logo from "../static/images/logo.svg"

export default function Header() {
  const [isBurgerMenuActive, setIsBurgerMenuActive] = useState(false)
  const [isUlMenuActive, setIsUlMenuActive] = useState(false)

  const menuHandler = () => {
    setIsBurgerMenuActive(!isBurgerMenuActive)
    setIsUlMenuActive(!isUlMenuActive)
  }
  return (
    <>
      {isBurgerMenuActive && (
        <Helmet
          bodyAttributes={{
            class: "body noScroll",
          }}
        />
      )}
      <Seo />
      <header class="c-header c-header-sm">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="c-header__main-info c-header__main-info-sm">
                <div class="c-header__main-info--logo c-header__main-info--logo-sm">
                  <Link to="/">
                    <img src={Logo} class="image-fluid" alt="Apricus Wealth Advisors" />
                  </Link>
                </div>
                <Nav />
                <button
                  class={`c-sticky-hamMenu js-hamDevice burger ${
                    isBurgerMenuActive ? `active` : ``
                  }`}
                  onClick={menuHandler}
                >
                  <span class="transition-med"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <!-- Begin Mobile Menu --> */}
      <div class={`responsive--menu ${isUlMenuActive ? `is--active` : ``}`} id="responsiveMenu">
        <ul class="mainNav">
          <NavItems isMobile={true} onMenuItemClick={menuHandler} />
        </ul>
      </div>
      <div class="responsive--menu-overlay"></div>
      {/* <!-- //End Mobile Menu --> */}

      {/* <!-- Begin Sticky Nav --> */}
      <NavSticky onMenuClick={menuHandler} isUlMenuActive={isUlMenuActive} />
      {/* <!-- //End Sticky Nav --> */}
    </>
  )
}
